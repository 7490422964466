import React from 'react';
import './Homepage.css'
import {Parallax} from 'react-parallax'
import { useState } from 'react';

export default function Homepage() {
  const[name,setName]=useState("Khoi Nguyen".split(''))
  return(

      <Parallax bgImage='/images/code2.jpg' 
        strength={-500}
        >
        <div className='home-page-container'>
          <div className='black-box'>
              <div className='home-page-header'>
                <h2 className='header-anime-home'>&lt;home&gt;</h2>
                <h1>
                  <span>
                    {name.map((char,index)=>(
                      <span style={{"animation-delay":(1+index/10)+"s"}}>
                        {char}
                      </span>
                    ))}
                  </span>
                </h1>
                <p className='home-subtitle'>I like to code and learn</p>
                <h2 className='header-anime-home'>&lt;/home&gt;</h2>
              </div>
          </div>
        </div>
      </Parallax>
  )
}

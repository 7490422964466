import React from 'react';
import './AboutMe.css'
import { useInView } from 'react-intersection-observer';
export default function AboutMe() {
  const [ref,inView] = useInView({threshold: .1,triggerOnce: true})
  return (
      <div className='about-me-container' id="about">
        
        <div className='about-content-container' ref={ref}>
            <div className='about-text-container flex-child'>
              <h2 className={inView?"header-anime":"none"}>&lt;about&gt;</h2>
              <p className={inView?"body-anime":"none"}>
              Hello! My name is Khoi Nguyen and I am from Milpitas, CA. I went to university at 
              the University of California, Irvine. I graduated Spring 2021 with a degree in 
              Computer Engineering. Having experience in both hardware and software, I decided
              that software was more for me because I love the practicality of coding and how it 
              can automate so many every day processes. Currently, I am self teaching myself React (by creating this portfolio website), 
              but I currently am working with Python at my job and have a ton of experience in Java, C, and 
              C++ from school.
              <br/>
              <br/>
              Some other hobbies I have outside of coding include: badminton, keyboards, Valorant, and swimming.
              </p>
              <h2 className={inView?"header-anime":"none"}>&lt;/about&gt;</h2>
              
            </div>
            <div className='about-pictures flex-child'>
              <div className={`about-me-image ${inView?"photo-anime":"none"}`}>
                <img src={"/images/testkhoi.PNG"} alt='Profile'></img>
                <div className={inView?"image-after":null}></div>
              </div>
              
              {/* <img src={"/images/testkhoi.PNG"} alt='Profile' className='about-me-image'></img> */}
            </div>
        </div>
        
      </div>
  )
}

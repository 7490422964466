import React from 'react'
import { useState } from 'react'
import { projectdata } from '../../data/projectdata'
import { useInView } from 'react-intersection-observer';
import Card from './Card'
import './Project.css'

export default function () {
    const [cards, setCards] = useState(projectdata)
    const [ref,inView] = useInView({threshold:.5, triggerOnce:true})
    return (
        <div>
            <div className='project-container' id="projects">
                <div className='project-content-container' ref={ref}>
                    <h2 className={inView?"header-anime":"none"}>&lt;projects&gt;</h2>
                    <div className='grid-container'>
                        {cards.map(({id, title, desc, link, image, languages})=>(
                            <div className='grid-child'>
                                <Card id={id} 
                                title={title}
                                desc={desc}
                                link={link}
                                image={image}
                                languages={languages} 
                                />
                            </div>
                        ))}
                    </div>
                    <h2 className={inView?"header-anime":"none"}>&lt;projects/&gt;</h2>
                </div>
            </div>
        </div>
  )
}

import { v4 as uuidv4 } from 'uuid';

export const projectdata =[
    {
        id: uuidv4(),
        title: "ChessNChat",
        desc:"Messenger application using socket communication to send messages with an implemented game of chess where board state is saved on a server.",
        link: "https://github.com/itKhoi/ChessNChat",
        image: "/images/chess.jpg",
        languages: "C, C++"
    },
    {
        id: uuidv4(),
        title: "Super Mario",
        desc: "Game of Super Mario intended for Android devices using Android Studio. Includes working levels with enemies and power-ups.",
        link: "https://github.com/itKhoi/SuperMario",
        image:"/images/Mario.jpg",
        languages: "Java"
    },
    {
        id: uuidv4(),
        title: "Fitness Tracker",
        desc:"Fitness workout planner that I developed just to teach myself React and Flask. Ability to make HTTP requests to a Flask backend to store data.",
        link: "https://github.com/itKhoi/fitness-react-app",
        image: "/images/weights.jpg",
        languages:"Javascript, Python, React"
    },
    {
        id: uuidv4(),
        title: "Irrigation Bot",
        desc:"Raspberry Pi project where a DHT22 is used to measure humidity and temperature. Amount of time to water is then calculated and displayed on LCD screen.",
        link: "https://github.com/itKhoi/IrrigationSimulation",
        image: "/images/farm.jpg",
        languages:"Python"
    }
]
import React from 'react'
import { useState,useEffect } from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import {GiHamburgerMenu} from "react-icons/gi"
import './NavBurger.css'
import { navdata } from '../../data/navdata'

export default function NavBurger() {
    const [burgerClick, setBurgerClick] = useState(false);
    const [scrollY, setScrollY] = useState(window.scrollY)
    const [lastScrollY, setLastScrollY] = useState(window.scrollY)
    const [show, setShow] = useState(true)
    const[navData, setnavData] = useState(navdata)

    useEffect(()=>{
        window.addEventListener('scroll',()=>{
            setScrollY(window.scrollY)
        })
    },[])
    useEffect(()=>{
        if (lastScrollY < scrollY){
            // console.log("going down");
            setShow(false)

        } else{
            // console.log("going up");
            setShow(true)
        }
        setLastScrollY(scrollY)
    },[scrollY])
    return (
        <div className='nav-burger-content'>
            <div className='nav-burger-container'>
                <GiHamburgerMenu className='burger-icon' onClick={
                    ()=>setBurgerClick(prev=>!prev)
                }/>
            </div>
            
            <div className={`burger-nav ${burgerClick?"burger-nav-clicked":"burger-back"} ${show?"":"burger-back"}` }>
                <div className='nav-burger-items'>
                    <ul>
                        {navData.map(({id,title,url})=>(
                            <li key={id} onClick={()=>{document.getElementById(url).scrollIntoView()
                                            setBurgerClick(prev=>!prev)}}>
                                <p key={title}>{title}</p>
                            </li>
                        ))}
                        <li>
                            <a href="/images/resume.pdf" target='_blank' rel='noopener noreferrer'>
                                <p>.resume</p>
                            </a>
                        </li> 
                    </ul> 
                </div>
            </div>  
        </div>
    )
}

import React from 'react'
import './Card.css'
export default function Card({id, title, desc, link, image, languages}) {
  return (
    <div>
        <div className='main-card-container'>
            <a href={link} target='_blank'>
            <div className='card-content'>
                <h3>{title}</h3>
                <p>{desc}</p>
                <p className='card-languages'>{languages}</p>
            </div>
            </a>
        </div>
    </div>
  )
}

import React from 'react'
import './LinkBar.css'
import {FaGithub, FaLinkedinIn, FaInstagram} from "react-icons/fa"

export default function LinkBar() {
  return (
    <div className='link-bar-container'>
        <a className="link-icon" href="https://www.linkedin.com/in/thekhoinguyen/" target="_blank" rel="noreferrer">
            <FaLinkedinIn className='overflow-vis'/>
        </a>
        <a className="link-icon" href="https://github.com/itKhoi" target="_blank" rel="noreferrer">
            <FaGithub className='overflow-vis'/>    
        </a>
        <a className="link-icon" href="https://www.instagram.com/bigboykhoi/" target="_blank" rel="noreferrer">
            <FaInstagram className='overflow-vis'/>    
        </a>
    </div>
  )
}

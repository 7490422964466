import { v4 as uuidv4 } from 'uuid';

export const navdata = [
    {
        id: uuidv4(),
        title: ".about",
        url: "about"
    },
    {
        id: uuidv4(),
        title: ".experience",
        url: "experience"
    },
    {
        id: uuidv4(),
        title: ".skills",
        url: "skills"
    },
    {
        id: uuidv4(),
        title: ".projects",
        url: "projects"
    },
    {
        id: uuidv4(),
        title: ".contact",
        url: "contact"
    }
]
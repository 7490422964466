import React from 'react'
import './Navi.css'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { useState } from 'react'
import { navdata } from '../../data/navdata'
// import Container from 'react-bootstrap/Container'
// import Button from 'react-bootstrap/Button'
// import {Link, scroller} from 'react-scroll'
// import Scroll from 'react-scroll'

export default function Navi() {
    const [burgerClick, setBurgerClick] = useState(false);
    const [navData,setnavData] = useState(navdata);
  return (
    <div className='nav-container'>
        <Navbar variant="dark" className="nav-bar">
            <Navbar.Brand href="/">
                <img
                alt=""
                src="/logo.png"
                width="40"
                height="40"
                className='d-inline-block align-top nav-brand'/>{' '}
                {/* Khoi Nguyen */}
            </Navbar.Brand>
            <Nav className="ms-auto nav-content">
                {navData.map(({id,title,url})=>(
                    <Nav.Item key={id}>
                        <Nav.Link onClick={()=>{document.getElementById(url).scrollIntoView()}}>
                            <p className='nav-text'>{title}</p>
                        </Nav.Link>
                    </Nav.Item>
                ))}
                <Nav.Item>
                    <a  href="/images/resume.pdf" target='_blank' rel='noopener noreferrer'>
                        <button className='nav-button'>
                            <p>Resume</p>
                        </button>
                    </a>
                </Nav.Item>

            </Nav>
            
        </Navbar>
    </div>
  )
}

import React from 'react'
import "./Contact.css"
import ContactForm from './ContactForm'
export default function 
Contact() {
  return (
    <div className='contact-container'>
        <div className='contact-form-container'>
            <ContactForm/>
        </div>
    </div>
  )
}

import React from 'react'
import "./Skills.css"
import { ProgressBar } from './Progressbar'
import {useState} from "react"
import {skilldata} from "../../data/skilldata"
import { useInView } from 'react-intersection-observer';
export default function Skills() {
    const [links, setLinks] = useState(skilldata)
    const [ref,inView] = useInView({threshold:.5, triggerOnce:true})
    return (
        <div className="skills-container" id="skills">
            <div className="skill-content-container" ref={ref}>
                <h2 className={inView?"header-anime":"none"}>&lt;skills&gt;</h2>
                <div className='progress-container'>
                    {links.map((link)=>{
                        const{title, width,percent} = link

                        return(
                            <div className='progress-box'>   
                                <p>{title}</p>
                                <ProgressBar width={width} percent={inView?percent:0}/>
                        </div>
                        )
                    })}
                </div>
                <h2 className={inView?"header-anime":"none"}>&lt;/skills&gt;</h2>
            </div>
        </div>
    )
}

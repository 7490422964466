import React from 'react'
import { useInView } from 'react-intersection-observer'
import { useState } from 'react'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import './Experience.css'
import { Row, Col } from 'react-bootstrap'
import Nav from 'react-bootstrap/Nav'
import { expdata } from '../../data/expdata'

export default function Experience() {
    const [ref, inView] = useInView({threshold:.5, triggerOnce:true})
    const [expData, setExpData] = useState(expdata)
    return (
    <div className='experience-container' ref={ref} id='experience'>
        <div className='experience-content-container' >
            <h2 className={inView?"header-anime":"none"}>&lt;experience&gt;</h2> 
            <Tab.Container id="experience-tabs" defaultActiveKey="first">
                <Row>
                    <Col lg={3}>
                        <Nav variant="pills" className="flex-column">
                            {/* <Nav.Item>
                                <Nav.Link eventKey="first">Tab 1</Nav.Link>
                            </Nav.Item> */}
                            {expData.map(({event_key, company_name,id})=>(
                                <Nav.Item id={id} key={id}>
                                    <Nav.Link eventKey={event_key}>{company_name}</Nav.Link>
                                </Nav.Item>
                            ))}
                        </Nav>
                    </Col>
                    <Col lg={9}>
                        <Tab.Content>
                            {/* <Tab.Pane eventKey="first">
                                <p>Hello</p>
                                <p>What is up</p>
                            </Tab.Pane> */}
                            {expData.map(({event_key, company_name,title,roles,id,date_employed}) => (
                                <Tab.Pane eventKey={event_key} id={id} key={id}>
                                    <h3>{title} @ {company_name} <span>{date_employed}</span></h3>
                                    <ul>
                                    {roles.map(role=>(
                                        <li key={role}>
                                            <p>{role}</p>
                                        </li>
                                    ))}
                                    </ul>
                                </Tab.Pane>
                            ))}    
                        </Tab.Content>    
                    </Col>
                </Row>
            </Tab.Container>
            <h2 className={inView?"header-anime":"none"}>&lt;experience/&gt;</h2>        
        </div>
        
    </div>
    )
}


import "./App.css"
import "./font.css"
import Homepage from "./modules/homepage/Homepage";
import AboutMe from "./modules/aboutme/AboutMe";
import Skills from "./modules/skills/Skills";
import Project from "./modules/projects/Project";
import Nav from "./modules/navbar/Nav";
import Contact from "./modules/contacts/Contact"
import Experience from "./modules/experience/Experience";


function App() {
  return (
    <div className="App">
      
      <div className="app-container">
        <Nav/>
        <Homepage/>
        <AboutMe/>
        <Experience/>
        <Skills/>
        <Project/>
        <Contact/>
        
        {/* <AboutMe/> */}
      </div>
    </div>
  );
}

export default App;

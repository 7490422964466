import {v4 as uuidv4} from 'uuid';

export const expdata = [
    {
        id: uuidv4(),
        event_key: "first",
        company_name:"MedLab 2020 inc.",
        title: "Software developer",
        roles: [
            "Develop scalable and maintainable code using Python and React for automation of COVID-19 resulting",
            "Quickly adapt in a fast paced environment due to rapid development in company size and structure",
            "Helped generate state reports for over 100,000 positive COVID-19 results anually through integration with AIMS Platform",
            "Created full-stack web application hosted on Azure for internal deployement which displayed resulting information and order status that is used by over 50 employees",
            "Created AWS MySQL database for servicing PCR tests that handles well over 2 million patient results"
        ],
        date_employed: "Sept 2021 - Present"
    },
    {
        id: uuidv4(),
        event_key:"second",
        company_name:"TBD",
        title:"Aspiring Software Engineer",
        roles:[
            "MedLab2020 is my first software job out of college!",
            "I will update this section as my employment opportunities widen!",
            "Quick learner, adaptive, team player"
        ],
        date_employed: "tbd"
    }
]
import React from 'react'
import { useState } from 'react'
import "./ContactForm.css"
import { useInView } from 'react-intersection-observer'
import {EmailJSResponseStatus, send} from 'emailjs-com'
import LinkBar from './LinkBar'

export default function ContactForm() {
    const[name, setName] = useState('');
    const[email,setEmail] = useState('');
    const[message, setMessage] = useState('');
    const[valid, setValid] = useState(true);
    const [ref, inView] = useInView({threshold: .5, triggerOnce:true})
    function submitContact(){
        // console.log('clicked');
        if(name.length == 0){
            document.getElementById('name').style.border = "1.5px solid red"
            return
        }else{
            document.getElementById('name').style.border = "1px solid black"
        }
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!re.test(email)){
            document.getElementById('email').style.border = "1.5px solid red"
            return
        }else{
            document.getElementById('email').style.border = "1px solid black"
        }
        if(message.length == 0){
            document.getElementById('message').style.border = "1.5px solid red"
            return
        }else{
            document.getElementById('message').style.border = "1px solid black"
        }
        

        var button = document.getElementById('form-button')
        button.classList.add("disabled")
        button.innerHTML= "Submitted!"
        button.style.backgroundColor = "rgba(72,81,136,0.3)"

        var toSend = {
            from_name: name,
            from_email: email,
            message: message
        }
        try{
            send(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID,
                toSend,process.env.REACT_APP_EMAILJS_USER_ID)
                .then(response => {
                    console.log('SUCCESS!', response.status, response.text);
                }).catch(err => {
                    var button = document.getElementById('form-button')
                    button.innerHTML = "Error!"
                    var messageBox = document.getElementById('message')
                    messageBox.value = "Error submitting email, please contact me at thekhoinguyenn@gmail.com. Be sure to let me know if this error popped up! Thank you."
                    messageBox.disabled = true
                })
        }catch{
            var button = document.getElementById('form-button');
            button.innerHTML = "Error!";
            var messageBox = document.getElementById('message')
            messageBox.value = "Error submitting email, please contact me at thekhoinguyenn@gmail.com. Be sure to let me know if this error popped up! Thank you."
            messageBox.disabled = true;
            document.getElementById('name').disabled=true;
            document.getElementById('email').disabled=true;
        }
        
    }
    return (
    <div>
        <div className='form-container' ref={ref} id="contact">
            <h2 className={inView?"header-anime": "none"}>&lt;contact&gt;</h2>
            <input id="name" type="text" className='form-input' placeholder='Name*' value={name} onChange={e=>setName(e.target.value)}/>
            <input id="email" type="email" className='form-input' placeholder="Email*" value={email} onChange={e=>setEmail(e.target.value)}/>
            <textarea id="message" className='form-input' placeholder="Message*" value={message} onChange={e=>setMessage(e.target.value)} rows="4"></textarea>
            <button className='contact-button' onClick={submitContact} id="form-button">
                Submit
            </button>
            <LinkBar/>
            <h2 className={inView?"header-anime": "none"}>&lt;contact/&gt;</h2>
        </div>
    </div>
  )
}

import React from 'react'
import { useEffect, useState } from 'react'
import './Nav.css'
import Navi from './Navi'
import NavBurger from './NavBurger'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Container from 'react-bootstrap/Container'

export default function () {
    const [scrollY, setScrollY] = useState(window.scrollY)
    const [lastScrollY, setLastScrollY] = useState(window.scrollY)
    const [show, setShow] = useState(false)

    useEffect(()=>{
        window.addEventListener('scroll',()=>{
            setScrollY(window.scrollY)
        })
    },[])
    useEffect(()=>{
        if (lastScrollY < scrollY){
            // console.log("going down");
            setShow(false)

        } else{
            // console.log("going up");
            setShow(true)
        }
        setLastScrollY(scrollY)
    },[scrollY])


    return (
        <div className={`navBar ${show?"show-animate":null}`}>
            <Navi/>
            <NavBurger/>
        </div>
    )
}
